import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ExecuteActionEvent, SendMessageEvent, Message, User } from '@progress/kendo-angular-conversational-ui';
import { merge, from, Observable, Subject } from 'rxjs';
import { scan, map } from 'rxjs/operators';
import { ChatBotService } from './chat-bot.service';
import { environment } from './../../environments/environment';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  hiddenChat = true;
  changeText = false;
  env = environment;
  public feed: Observable<Message[]>;

  public readonly user: User = {
    id: UUID.UUID(),
    name: 'You',
    avatarUrl: ''
  };

  public readonly bot: User = {
    id: 0,
    name: environment.chatBotName,
    avatarUrl: environment.chatBotAvatarUrl
  };

  private local: Subject<Message> = new Subject<Message>();

  constructor(private chatBotService: ChatBotService,
              private router: Router) {
    const hello: Message = {
      author: this.bot,
      timestamp: new Date(),
      text: 'Hi I\'m ' + this.bot.name + '! I\'m here to listen to your dirty dreams.'
    };

    // Merge local and remote messages into a single stream
    this.feed = merge(
      from([ hello ]),
      this.local,
      this.chatBotService.responses.pipe(
        map((response): Message => (response)
      ))
    ).pipe(
      // ... and emit an array of all messages
      scan((acc: Message[], x: Message) => [...acc, x], [])
    );
  }

  public sendMessage(e: SendMessageEvent): void {

    this.local.next(e.message);

    this.chatBotService.submit(e.message.text, e.message.author.id);
  }

  public onAction(e: ExecuteActionEvent): void {
    if (e.action.type === 'custom') {
      console.log('Action', e.action.value);
      this.hiddenChat = true;
      if (e.action.value.indexOf('?') !== -1) {
        this.router.navigate([e.action.value.substr(0, e.action.value.indexOf('?'))], { queryParams: this.getParameters(e.action.value)});
      } else {
        this.router.navigate([e.action.value]);
      }
    }
  }

  public onCardAction(title: string, payload: string, type: string): void {
//	  alert(title + " " + payload + " " + type);

	const cardMessage: Message = {
      author: this.user,
	  timestamp: new Date(),
      text: title
    };

	this.local.next(cardMessage);

    this.chatBotService.submit(payload, cardMessage.author.id);
  }

  private getParameters(param: string): Params {
    const params: Params = {};
    const parameters = param.substr(param.indexOf('?') + 1);
    const listParameters: Array<string> = parameters.split('&');
    listParameters.forEach(e => {
      const keyValue = e.split('=');
      params[keyValue[0]] = keyValue[1];
    });
    return params;
  }

  ngOnInit(): void {
    this.hiddenChat = true;
  }

}
