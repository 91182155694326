import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { IntlModule } from '@progress/kendo-angular-intl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChatBotComponent } from './chat-bot/chat-bot.component';


import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/it/calendar';
import { HomepageComponent } from './homepage/homepage.component';
import { ChatBotHelpComponent } from './chat-bot-help/chat-bot-help.component';


@NgModule({
  declarations: [
    AppComponent,
    ChatBotComponent,
    HomepageComponent,
    ChatBotHelpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChatModule,
    IntlModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
