import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ChatBotHelpComponent } from './chat-bot-help/chat-bot-help.component';


const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'aiuto', component: ChatBotHelpComponent},
  {path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
