import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-chat-bot-help',
  templateUrl: './chat-bot-help.component.html',
  styleUrls: ['./chat-bot-help.component.scss']
})
export class ChatBotHelpComponent implements OnInit {

  env = environment;

  constructor() { }

  ngOnInit() {
  }

}
