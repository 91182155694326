export class ChatBotGenericTemplate {
    text: string;
    cardNum: number;
	cards: Array<ChatBotCard>;
}

export class ChatBotCard {
	title: string;
	subtitle: string;
	contentType: string;
	content: string;
	sharable: string;
	buttons: number;
	button: Array<ChatBotButton>;
}

export class ChatBotButton {
    title: string;
    payload: string;
	type: string;
	url: string;
}

export class ChatBotQuickReplay {
    label: string;
    payload: string;
	type: string;
	target: string;
}

export class ChatBotRequest {
    message: string;
	user: string;
    exstraInfo: Map<string, string>;
}

export class ChatBotResponse {
    messages: Array<string>;
    delay: number;
    quickReplay: Array<ChatBotQuickReplay>;
	cards: Array<ChatBotCard>;

    constructor() {
        this.messages = [];
        this.delay = 0;
        this.quickReplay = [];
        this.cards = [];
    }
}
