import { Injectable } from '@angular/core';
import { Message, Action, User, Attachment } from '@progress/kendo-angular-conversational-ui';
import { interval, Subject, of } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';
import { ChatBotResponse, ChatBotRequest, ChatBotCard, ChatBotButton } from './chat-bot.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({providedIn: 'root'})
export class ChatBotService {

    private chatBotRootUrl = environment.chatBotUrl;

	private chatBotRequest: ChatBotRequest = new ChatBotRequest();

    public readonly responses: Subject<Message> = new Subject<Message>();

    public readonly bot: User = {
        id: 0,
        name: environment.chatBotName,
        avatarUrl: environment.chatBotAvatarUrl
      };

    message: Message = {
       author: this.bot
    };

    private oldResponse: ChatBotResponse;

    constructor(private httpClient: HttpClient) { }

    public submit(question: string, user: string): void {
		this.chatBotRequest.message = question;
		this.chatBotRequest.user = user;
        if (this.oldResponse && this.oldResponse.quickReplay) {
            this.oldResponse.quickReplay.forEach(element => {
                if (element.label === this.chatBotRequest.message) {
                    this.chatBotRequest.message = element.payload;
                }
            });
        }

        this.httpClient.post<ChatBotResponse>(this.chatBotRootUrl, this.chatBotRequest).pipe(
            catchError( err => {
                const chatBotResponse = new ChatBotResponse();
                chatBotResponse.messages = ['Si è verificato un errore di comunicazione con il server.'];
                chatBotResponse.delay = 200;
                return of(chatBotResponse);
            })
        ).subscribe(this.manageBotResponse.bind(this));
    }

    private manageBotResponse(data: ChatBotResponse): void {
        this.oldResponse = data;
        if (data.messages.length > 0) {
            this.responses.next({
                author: this.bot,
                timestamp: new Date(),
                typing: true
            });
        }
        interval(data.delay).pipe(
            take(data.messages.length),
            map(i => data.messages[i])
        ).subscribe(
            (text: string) => {
                this.responses.next({
                    author: this.bot,
                    timestamp: new Date(),
                    text
                });
            },
            () => {},
            () => {
                if (data.quickReplay.length > 0) {
                    const suggestedActions: Array<Action> = new Array<Action>();
                    if (data.quickReplay) {
                        data.quickReplay.forEach(button => {
							if(button.type === 'text')
							{
								suggestedActions.push({
									type: 'reply',
									value: button.label
								});
							}
							else
							{
								suggestedActions.push({
									type: (button.target === 'EXT') ? 'openUrl' : 'custom',
									title: button.label,
									value: button.payload
								});
							}
                        });
                    }

                    setTimeout( () => {
                        this.responses.next({
                            author: this.bot,
                            suggestedActions,
                            timestamp: new Date()
                        });
                    }, data.delay);
                }
				
				if (data.cards.length > 0) {
                    const attachments: Array<ChatBotCard> = new Array<ChatBotCard>();
                    if (data.cards) {
                        data.cards.forEach(card => {
                            attachments.push(card);
                        });
                    }

                    setTimeout( () => {
                        this.responses.next({
                            author: this.bot,
                            attachments,
                            timestamp: new Date()
                        });
                    }, data.delay);
                }

            }
        );
    }

}
